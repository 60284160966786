@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"),
    url("Fonts/Open_Sans/OpenSans-Regular.ttf") format("ttf");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans"), local("OpenSans"),
    url("Fonts/Open_Sans/OpenSans-Light.ttf") format("ttf");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans"), local("OpenSans"),
    url("Fonts/Open_Sans/OpenSans-SemiBold.ttf") format("ttf");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans"), local("OpenSans"),
    url("Fonts/Open_Sans/OpenSans-Bold.ttf") format("ttf");
}

body {
  margin: 0;
  padding: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  min-width: 1920px;
  min-height: 1080px;
  overflow: hidden;
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
  background-color: #0e1219;
  color: #dfe9e8;
}
::-webkit-scrollbar:horizontal {
  height: 10px;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track:horizontal {
  /* -webkit-box-shadow: inset 0 0 6px #16181f; */
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: #21242d;
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: #db0a41;
  cursor: pointer;
}

/* Handle */
::-webkit-scrollbar-thumb:horizontal {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: #db0a41;
  cursor: pointer;
}
